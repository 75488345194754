import React from 'react';
import cx from 'classnames';
import { Box } from '../Box';
import { Rank } from '../Rank';
import { Text } from '../Text';
import { PageRankSummaryProps } from './types';

export const PageRankSummary = ({
  position,
  title,
  url,
}: PageRankSummaryProps) => {
  const href = url.indexOf('http') === 0 ? url : `https://${url}`;
  return (
    <Box className="grid grid-cols-[max-content_1fr] gap-4 items-center">
      <Rank size={38} value={position} />
      <Box className="w-full overflow-hidden">
        {title && (
          <Text as="p" className="truncate">
            {title}
          </Text>
        )}
        <a
          href={href}
          className={cx([
            'w-min',
            'max-w-full',
            'flow-root',
            'truncate',
            'text-blue-400',
            'hover:underline',
          ])}
          target="_blank"
          rel="noreferrer"
          data-mms--outbound-action="page-summary-link"
        >
          {url}
        </a>
      </Box>
    </Box>
  );
};
