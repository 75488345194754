import { twMerge } from 'tailwind-merge';
import { Box, BoxProps, Text } from '@marketmuse/components';
import { LinkingRecommendationForTerm } from '@marketmuse/config/types/papi';

type LinkListProps = BoxProps & LinkingRecommendationForTerm;

const colClassNames = ['truncate', 'p-4', 'border-b', 'border-gray-50'];

function LinkList({ term, recommendations, ...rest }: LinkListProps) {
  return (
    <Box {...rest}>
      {term && (
        <Text as="h2" className="uppercase text-gray-500 font-semibold mb-2">
          {term}
        </Text>
      )}

      <div className="w-full ">
        {Array.isArray(recommendations) &&
          recommendations.map(({ title, url }, index) => (
            <div key={index} className="flex bg-white">
              <div className={twMerge(colClassNames, 'w-4/6')}>
                <Text>{title}</Text>
              </div>
              <div className={twMerge(colClassNames, 'w-2/6', 'text-blue-400')}>
                <a
                  className="text-blue-400"
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {url}
                </a>
              </div>
            </div>
          ))}
      </div>
    </Box>
  );
}

export default LinkList;
