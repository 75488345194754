import { Suspense } from 'react';
import { PAPI } from '@marketmuse/config/types';
import { ACTION_TYPES, MISC_SIMPLE_KEYS } from '@marketmuse/data-state';
import {
  useStoreDispatch,
  useStoreSelector,
} from '@marketmuse/data-state/hooks';
import { miscActions } from '@marketmuse/data-state/misc';
import { lazyLoad } from '@marketmuse/utilities';

import { SIGNUP_STEPS } from '../../config/signupSteps';
import { toggleDynamic } from '../../actions/miscActions';
import UserLookup from '../../components/UserLookup/UserLookup';

import CreateOrg from '../Admin/Orgs/CreateOrg';
import InventoryCsvModal from '../Inventory/components/InventoryCsvModal';
import CalendlyModal from '../Payments/CalendlyModal';
import LockedFeatureWarning from '../Payments/LockedFeatureWarning';

import BriefCancelModal from '../Brief/BriefCancelModal';

const DocumentCreation = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'DocumentCreation' */ '@marketmuse/content-document-creation/DocumentCreation'
    ),
);
const SubscriptionLadder = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'SubscriptionLadder' */ '@marketmuse/account-subscription/SubscriptionLadder'
    ),
);

const SubscriptionModals = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'SubscriptionModals' */ '@marketmuse/account-subscription/SubscriptionModals'
    ),
);

const AppModalsMiscState = () => {
  const dispatch = useStoreDispatch();

  const onboardingStep = useStoreSelector(state => state.user?.onboardingStep);
  const showSubscriptionManagement = useStoreSelector(
    state => state.misc[MISC_SIMPLE_KEYS.subscriptionManagement],
  );
  const subscriptionManagementData = useStoreSelector(
    state => state.misc[MISC_SIMPLE_KEYS.subscriptionManagementData],
  );

  const inSignupPayment = SIGNUP_STEPS.payment.includes(onboardingStep);

  const misc = useStoreSelector(state => state.misc);
  const documentsCreate = useStoreSelector(
    state => state.misc[MISC_SIMPLE_KEYS.documentsCreate],
  );

  return (
    <>
      {misc['uploadInventoryCsvModal'] && <InventoryCsvModal />}
      {misc['create-org'] && <CreateOrg />}
      {misc['briefCancellation'] && <BriefCancelModal />}
      {misc['userLookup'] && (
        <UserLookup
          close={() => dispatch(toggleDynamic({ id: 'userLookup' }))}
        />
      )}
      {misc['calendlyPremium'] && <CalendlyModal />}
      <Suspense fallback={<div></div>}>
        {documentsCreate?.isVisible && (
          <DocumentCreation
            items={documentsCreate.items}
            onClose={() => {
              dispatch(
                miscActions.setSimple({
                  key: MISC_SIMPLE_KEYS.documentsCreate,
                  value: {
                    isVisible: false,
                  },
                }),
              );
            }}
          />
        )}
      </Suspense>
      {misc['upgradeWarning'] && <LockedFeatureWarning />}

      {(showSubscriptionManagement || inSignupPayment) && (
        <Suspense fallback={<div />}>
          <SubscriptionLadder
            planName={
              subscriptionManagementData?.planName ||
              (onboardingStep?.split(':')[1] as PAPI.SubscriptionPlanType)
            }
            onClose={() => {
              dispatch({
                type: ACTION_TYPES.TOGGLE_DYNAMIC,
                payload: {
                  id: MISC_SIMPLE_KEYS.subscriptionManagement,
                  status: false,
                  data: null,
                },
              });
            }}
          />
        </Suspense>
      )}

      <SubscriptionModals />
    </>
  );
};

export default AppModalsMiscState;
