import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { capitalize, startCase } from 'lodash';
import { validateFocusTerm, isPageUrl } from '@marketmuse/utilities';

import Modal, { ModalBody } from '../../../components/Modal';
import Portal from '../../../components/Portal';
import Button from '../../../components/Button';

import * as miscActions from '../../../actions/miscActions';
import * as inventoryActions from '../../../actions/inventoryActions';
import jsonToCsv from '../../Admin/ReportsManager/orgPerformance/jsonToCsv';
import { csvToJson } from '../../../utils/csvStringToJson';

const ModalBodyStyled = styled(ModalBody)`
  width: 100%;
  max-width: 840px;
  margin: auto;
  padding: 100px 20px;
`;

const Wrapper = styled.div`
  padding: 40px 40px 50px 40px;
  ${p => p.theme.mixins.minMediaQuery.sm`
    padding: 80px 58px 74px 58px;
  `}
  ${p => p.theme.mixins.minMediaQuery.lg`
    padding: 80px 88px 74px 88px;
  `}
`;

const Header = styled.div`
  ${p => p.theme.type.textLarge}
  font-size: 24px;
  line-height: 32px;
  color: ${p => p.theme.colors.grey85};
  font-weight: bold;
  margin: 0 0 12px 0;
  display: flex;
`;

const LargeText = styled.div`
  ${p => p.theme.type.textRegular}
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  display: flex;
  margin-bottom: ${p => p.theme.spacing.small};
`;

const FormText = styled.div`
  ${p => p.theme.type.textLarge}
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: ${p => p.theme.colors[p.textColor || 'grey60']};
  padding: 60px ${p => p.theme.spacing.large};
  white-space: pre-wrap;
`;

const Form = styled.div`
  position: relative;
  outline: none;
  width: 100%;
  border: ${p => p.theme.spacing.xxSmall} solid ${p => p.theme.colors.grey10};
  border-radius: ${p => p.theme.spacing.xxSmall};
  max-width: 792px;
  height: 100%;
  margin-top: ${p => p.theme.spacing.large};
  ${p =>
    (p.isActive || p.isLoading) &&
    `
    border: ${p.theme.spacing.xxSmall} solid ${p.theme.colors.blueLight2};
    background-color: ${p.theme.colors.blueLight2};
  `}
  ${p =>
    p.isApplied &&
    `
    border: ${p.theme.spacing.xxSmall} solid ${p.theme.colors.greenBase};
    background-color: ${p.theme.colors.greenBase};
  `}
`;

const LinkText = styled.span`
  ${p => p.theme.type.textLarge}
  font-weight: bold;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: ${p => p.theme.colors.blueDark1};
  border-bottom: 1px solid ${p => p.theme.colors.blueDark1};
  cursor: pointer;
`;

const defaultErrorMessage = 'Topic or Url is required';

const parseFieldValue = (key, item) => {
  const value = item[key]?.trim();
  const urlValidation = isPageUrl(item?.URL);
  const termValidation = validateFocusTerm(item?.TOPIC);
  switch (key) {
    case 'TOPIC': {
      let error = null;
      if (!termValidation && (!item?.URL?.trim() || !urlValidation.valid)) {
        error = defaultErrorMessage;
      }
      return { value, error };
    }
    case 'URL': {
      let error = null;
      if (!value && !item?.TOPIC?.trim()) {
        error = defaultErrorMessage;
      } else if (value && !urlValidation.valid) {
        error = urlValidation.reason;
      }

      return { value, error };
    }

    default: {
      return { value };
    }
  }
};
const serializeCsvItem = ({ headers, headersKeys }, item = {}, index) => {
  const serializedData = { id: index, skipMemo: true };

  headersKeys.forEach(key => {
    if (item[key] !== void 0) {
      const accessor = headers[key];
      const updates = parseFieldValue(key, item);
      const [object, field] = accessor.split('.');
      if (object && field) {
        if (serializedData?.[object]) {
          serializedData[object][field] = updates;
        } else {
          serializedData[object] = { [field]: updates };
        }
      } else {
        serializedData[object] = updates;
      }
    }
  });
  return serializedData;
};
const headers = {
  TOPIC: 'topic.term',
  URL: 'page.url',
};
const headersKeys = Object.keys(headers);

const InventoryCsvModal = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [isApplied, setIsApplied] = useState(false);
  const [parsedData, setParsedData] = useState(null);

  const closeModal = data => {
    props.miscActions.toggleDynamic({
      id: 'uploadInventoryCsvModal',
      status: false,
      data: null,
    });
  };

  const downloadTemplate = async () => {
    jsonToCsv(
      headersKeys.map(header => startCase(capitalize(header))),
      [
        ['Topic 1', 'https://example1.com'],
        ['Topic 2', 'https://example2.com'],
      ],
      'exampleTemplate',
    );
  };

  const onDrop = useCallback(() => {
    setIsLoading(true);
  }, []);

  const onDropAccepted = useCallback(
    acceptedFiles => {
      const reader = new FileReader();
      reader.onload = function (e) {
        const allCsvJson = csvToJson(reader.result, true).filter(
          item => item?.TOPIC?.trim() || item?.URL?.trim(),
        );
        const csvJson = allCsvJson.slice(0, 500);
        const rowsCount = allCsvJson.length;

        const serializedData = csvJson.map((item, index) =>
          serializeCsvItem(
            { headers, headersKeys },
            item,
            index + props.lastIndex,
          ),
        );
        let message;
        if (rowsCount > 500) {
          message = `
          The first 500 rows of your file were uploaded.
          Your file appears to be ${rowsCount} rows, so you will need to upload rows 501-${rowsCount} separately.
        `;
        }
        if (serializedData.length) {
          props.callback(serializedData, message);

          const pagesCount =
            serializedData.filter(item => item?.page?.url?.value)?.length ?? 0;
          setParsedData({
            topicsCount: serializedData?.length - pagesCount,
            pagesCount,
          });

          setIsApplied(true);
          setTimeout(() => {
            closeModal();
          }, 1000);
        }
        setIsLoading(false);
      };
      reader.readAsText(acceptedFiles[0]);
    },
    [headers, headersKeys],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropAccepted,
    multiple: false,
    accept: '.csv',
  });

  return (
    <Portal>
      <Modal
        close={() => closeModal({ success: false })}
        style={{
          width: '100%',
        }}
        closeStyle={{
          right: '10px',
          top: '10px',
          left: 'auto',
          boxShadow: 'none',
          color: '#AEAEAE',
          zIndex: 10000,
        }}
      >
        <ModalBodyStyled>
          <Wrapper>
            <Header>Upload CSV</Header>
            <LargeText>
              Use this tool to import large amounts of Pages and or Topics.
              <br />
              We currently have a 500 row/upload limit.
            </LargeText>

            <Button
              secondary
              large
              fontSize="14px"
              lineHeight="22px"
              onClick={() => downloadTemplate()}
            >
              Download Example Template
            </Button>
            <Form
              isLoading={isLoading}
              isActive={!!isDragActive}
              isApplied={!!isApplied}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {!isDragActive && !isLoading && !isApplied && (
                <FormText>
                  Drag your CSV file here or{' '}
                  <LinkText>browse your files</LinkText>.
                </FormText>
              )}
              {isDragActive && (
                <FormText textColor="blueDark1">
                  Drop the files here ...
                </FormText>
              )}
              {isLoading && (
                <FormText textColor="blueDark1">Processing</FormText>
              )}
              {isApplied && (
                <FormText textColor="white">
                  CSV upload successful!
                  <br />
                  Detected
                  {!!parsedData?.topicsCount &&
                    ` ${parsedData?.topicsCount} topic${
                      parsedData?.topicsCount === 1 ? '' : 's'
                    }`}
                  {!!parsedData?.topicsCount &&
                    !!parsedData?.pagesCount &&
                    ', '}
                  {!!parsedData?.pagesCount &&
                    ` ${parsedData?.pagesCount} page${
                      parsedData?.pagesCount === 1 ? '' : 's'
                    }`}
                </FormText>
              )}
            </Form>
          </Wrapper>
        </ModalBodyStyled>
      </Modal>
    </Portal>
  );
};

InventoryCsvModal.propTypes = {
  callback: PropTypes.func,
  lastIndex: PropTypes.number,
  filter: PropTypes.shape(),
  misc: PropTypes.shape(),
  onClose: PropTypes.func,
  // actions
  miscActions: PropTypes.object,
  inventoryActions: PropTypes.object,
};

const mapDispatchToProps = dispatch => ({
  miscActions: bindActionCreators(miscActions, dispatch),
  inventoryActions: bindActionCreators(inventoryActions, dispatch),
});

const mapStateToProps = state => {
  const modalData = state?.misc?.['uploadInventoryCsvModal-data'] ?? {};
  return {
    misc: state.misc,
    filter: state.filter,
    callback:
      typeof modalData.callback === 'function' ? modalData.callback : () => {},
    lastIndex: modalData.lastIndex || 0,
  };
};

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(InventoryCsvModal),
);
