import React, { ReactNode } from 'react';
import cx from 'classnames';

import { Polymorphic } from '../../Polymorphic';

export interface TextProps {
  className?: string | Array<string | string[]>;
  as?: React.ElementType<any>;
  children: ReactNode;
}

export const Text = React.forwardRef<HTMLSpanElement, TextProps>(
  ({ as, className, ...props }, ref) => {
    const element = as || 'span';
    return (
      <Polymorphic
        {...props}
        as={element}
        className={cx(className)}
        ref={ref}
      />
    );
  },
);

export default Text;
