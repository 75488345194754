import download from 'downloadjs';

/**
 * Function to export the object as csv.
 * @param  {array} headers the columns of the csv file.
 * @param  {array} exportable the records of csv file.
 * @param  {string} fileName the title of the file which needs to be exported.
 */
const jsonToCsv = (headers, exportable, fileName) => {
  const data = [].concat([headers], exportable);
  const dataArray = Array.from(data);
  const flat = dataArray.map(row => row.join(',')).join('\r\n');

  download(flat, `${fileName}.csv`, 'text/csv');
};

export default jsonToCsv;
