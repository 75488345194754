const CSVToArray = (strData, strDelimiter = ",") => {
  const objPattern = new RegExp(
    (
      // Delimiters.
      "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
      // Quoted fields.
      "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
      // Standard fields.
      "([^\"\\" + strDelimiter + "\\r\\n]*))"
    ),
    "gi"
  );
  const arrData = [[]];
  let arrMatches = null;
  const getArrMatches = () => {
    arrMatches = objPattern.exec( strData );
    return arrMatches;
  }
  while (getArrMatches()) {
    const strMatchedDelimiter = arrMatches[ 1 ];
    if (strMatchedDelimiter.length && (strMatchedDelimiter !== strDelimiter)) {
      arrData.push([]);
    }
    let strMatchedValue;
    if (arrMatches[ 2 ]) {
      strMatchedValue = arrMatches[ 2 ].replace(
        new RegExp( "\"\"", "g" ),
        "\""
      );
    } else {
      strMatchedValue = arrMatches[ 3 ];
    }
    arrData[ arrData.length - 1 ].push( strMatchedValue );
  }
  return( arrData );
};

export const csvToJson = (str, isUppercaseHeaders) => {
  const data = [];
  let i = 0;
  let k = 0;
  let header = [];
  const csvLines = CSVToArray(str);

  for ( i = 0; i < csvLines.length; i++ ) {
    if ( i === 0 ) {
      header = csvLines[i];
    } else {
      const obj = {};
      for ( k = 0; k < header.length; k++ ) {
        const serializedHeader = isUppercaseHeaders
          ? header[k]?.toUpperCase()
          : header[k];
        if ( k < csvLines[i].length ) {
          obj[serializedHeader] = csvLines[i][k];
        }
      }
      data.push(obj);
    }
  }
  return data;
};
