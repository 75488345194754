import requestMiddleware from './request';
import auth from './auth';
import site from './site';

// new middlewares
import downloadPage from './tools/downloadPage';
import getExcel from './tools/getExcel';
import deleteOrg from './admin/deleteOrg';
import createReport from './briefs/createReport';
import updateReport from './briefs/updateReport';
import deleteReport from './briefs/deleteReport';
import addSubheading from './briefs/addSubheading';
import readReport from './briefs/readReport';
import readReports from './briefs/readReports';
import updateReportFormData from './briefs/updateReportFormData';
import getQuestionsData from './briefs/getQuestionsData';

import generateReport from './briefs/generateReport';
import seoKnowledgeGraphAndVariants from './inventory_new/seoKnowledgeGraphAndVariants';
import getAggregateStats from './site/getAggregateStats';
import getSitesByOrg from './site/getSitesByOrg';
import getOrgs from './admin/getOrgs';
import getOrg from './admin/getOrg';
import getUsersAdmin from './admin/getUsersAdmin';
import updateOrgData from './auth/updateOrgData';
import updateUserData from './auth/updateUserData';
import deleteUser from './auth/deleteUser';
import getUserData from './auth/getUserData';
import getAppData from './tools/getAppData';

import setAppsOnboarding from './tools/setAppsOnboarding';
import setAppsHydrating from './tools/setAppsHydrating';
import getInventorySubdomains from './inventory_new/getInventorySubdomains';
import inventoryItemsAdd from './inventory_new/inventoryItemsAdd';
import inventoryItemsUpdate from './inventory_new/inventoryItemsUpdate';
import inventoryCreateOrg from './inventory_new/inventoryCreateOrg';
import inventoryCreate from './inventory_new/inventoryCreate';
import inventoryUpdate from './inventory_new/inventoryUpdate';
import addMember from './auth/addMember';
import getForms from './formBuilder/getForms';
import getForm from './formBuilder/getForm';
import updateForm from './formBuilder/updateForm';
import createForm from './formBuilder/createForm';
import updateFormGroup from './formBuilder/updateFormGroup';
import createFormGroup from './formBuilder/createFormGroup';
import updateFormField from './formBuilder/updateFormField';
import createFormField from './formBuilder/createFormField';
import getAppQueries from './tools/getAppQueries';
import getSnapshotData from './misc/getSnapshotData';
import validateEmail from './misc/validateEmail';
import inquirePremium from './misc/inquirePremium';
import recurlyGetAccount from './recurly/recurlyGetAccount';
import recurlyGetSubscriptions from './recurly/recurlyGetSubscriptions';
import recurlyGetTransactions from './recurly/recurlyGetTransactions';
import recurlyHydrateState from './recurly/recurlyHydrateState';
import recurlyCreateAccount from './recurly/recurlyCreateAccount';
import recurlyUpsertBillingInfo from './recurly/recurlyUpsertBillingInfo';
import recurlyValidateCoupon from './recurly/recurlyValidateCoupon';

import guestAccessCreate from '././guestAccess/guestAccessCreate';
import guestAccessTokenGetMany from './guestAccess/guestAccessTokenGetMany';
import upsertProject from './projects/upsertProject';
import upsertProjects from './projects/upsertProjects';
import getProjects from './projects/getProjects';
import getProject from './projects/getProject';

import removeProjectOrder from './projects/removeProjectOrder';
import projectPlaceOrders from './projects/projectPlaceOrders';

import createSavedView from './savedView/createSavedView';
import getSavedViews from './savedView/getSavedViews';
import deleteSavedView from './savedView/deleteSavedView';
import getSavedViewsEach from './savedView/getSavedViewsEach';

export default () => [
  requestMiddleware(),
  auth(),
  site(),
  downloadPage(),
  getExcel(),
  createReport(),
  readReports(),
  readReport(),
  addSubheading(),
  updateReport(),
  getQuestionsData(),
  generateReport(),
  deleteReport(),
  seoKnowledgeGraphAndVariants(),
  getOrgs(),
  getOrg(),
  getSitesByOrg(),
  getUsersAdmin(),
  updateOrgData(),
  updateUserData(),
  deleteUser(),
  getUserData(),
  getAppData(),
  setAppsOnboarding(),
  setAppsHydrating(),
  getInventorySubdomains(),
  inventoryItemsAdd(),
  inventoryItemsUpdate(),
  inventoryCreateOrg(),
  inventoryCreate(),
  inventoryUpdate(),
  getAggregateStats(),
  addMember(),
  deleteOrg(),
  updateForm(),
  createForm(),
  updateFormGroup(),
  createFormGroup(),
  getForms(),
  getForm(),
  updateFormField(),
  createFormField(),
  updateReportFormData(),
  getAppQueries(),
  getSnapshotData(),
  validateEmail(),
  inquirePremium(),
  recurlyCreateAccount(),
  recurlyGetAccount(),
  recurlyGetSubscriptions(),
  recurlyGetTransactions(),
  recurlyHydrateState(),
  recurlyUpsertBillingInfo(),
  recurlyValidateCoupon(),

  guestAccessCreate(),
  guestAccessTokenGetMany(),

  upsertProject(),
  upsertProjects(),
  getProjects(),
  getProject(),
  removeProjectOrder(),
  projectPlaceOrders(),
  createSavedView(),
  getSavedViews(),
  deleteSavedView(),
  getSavedViewsEach(),
];
